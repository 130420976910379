
import Vue from "vue"
import { aclWrapper } from "@/utilities/aclWrapper"
import { Client } from "@/types/responses"

const ClientInfo = () => import("@/components/customer/ClientInfo.vue").then(m => m.default || m)
const PersonsList = () => import("@/components/customer/PersonsList.vue").then(m => m.default || m)

import { CustomerEnum } from "@/types/enums"
import { Route } from "vue-router"

export default Vue.extend({
	name: "ClientOrganization",
	data(): {
        componentKey: number
		currentModulePath: CustomerEnum.CustomerPersons
		parentModulePath: string
		parentName: string
		// eslint-disable-next-line
        tab: null | Record<string, unknown>
		// eslint-disable-next-line
        clientTabs: null | Record<string, any>
        minHeight: number | string
	} {
		return {
            componentKey: 0,
			currentModulePath: CustomerEnum.CustomerPersons,
			parentModulePath: this.$router.currentRoute.path.split("/")[1],
			parentName: this.$router.currentRoute.name ? this.$router.currentRoute.name : "",
            tab: null,
			clientTabs: null,
            minHeight: "100%"
		}
	},
    async mounted(){
		this.clientTabs = (this.$refs as any).clientTabs;
		this.setTabsNavHeight()
    },
	components: {
		ClientInfo
	},
	watch: {
		$route(to: Route, from: Route) {
			this.parentModulePath = to.path.split("/")[1]
			this.parentName = to.name ? to.name : ""
		},
		getSelectedClient: {
            deep: true,
            handler(){
                console.log("selected client has been updated")
                this.updateComponentKey()
            }
        }
	},
    computed: {
        getSelectedClient(): Client | null {
            return this.$store.state.context.selectedClient
        },
		getSelectedClientName(): string {
			if (this.$store.state.context.selectedClient) {
				return this.$store.state.context.selectedClient.name
			} else {
				return ""
			}
		},
        tabContent(): Array<unknown>{
            const tabs = [
                {
                    id: 1,
                    title: "CONTACTS",
                    subtitle:"External contacts and employees with limited access",
                    component: PersonsList
                }
            ]

            return tabs;
        }
	},
    methods: {
        async updateComponentKey(): Promise<void> {
            this.componentKey++;
        },
        closeDialog(isLastMember?: boolean): void{
            
            console.log("[ClientOrganization] Emitting closeDialog")
            if(isLastMember){
                console.log("[ClientOrganization] Is Last member in ClientOrg.")
                this.$emit('closeDialog', true)
            }else {
            
                this.$emit('closeDialog', null)
            }

        },
		setTabsNavHeight(): number | string {
            if(this.clientTabs == null) {
                return 0
            }
            else {
                // eslint-disable-next-line
                this.clientTabs.$children.forEach((tab: any) => {
                    if(
                        tab.$el.clientHeight >= this.minHeight || 
                        this.minHeight == undefined || 
                        this.minHeight == "100%" 
                    ){
                        this.minHeight = tab.$el.clientHeight;
                    }
                });
                return this.minHeight
            }
        }
    }
    
})
